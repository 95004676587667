import React from "react";
import "./breadcrumb.css";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";

export default function Breadcrumb({ title }){
    return(
        <React.Fragment>
            <section className="breadcrumarea">
                <div className="container">
                    <div className="breadcrumdata">
                        <h1>{title}</h1>
                        <ul>
                            <li><Link to="/">Home</Link> <FaArrowRightLong/></li>
                            <li>{title}</li>
                        </ul>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}