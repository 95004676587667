import React from "react";
import "./contactus.css";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Breadcrumb from "../../Components/Breadcrumb/breadcrumb";

export default function ContactUs(){
    return(
        <React.Fragment>
            <Header/>
            <Breadcrumb title={'Contact Us'}/>
            <section className="contactusarea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contactimage">
                                <img src="https://placehold.co/600x500" alt="Contact Us" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contactform">
                                <div className="contactformhead">
                                    <h2>Get In Touch</h2>
                                </div>
                                <form action="" method="post">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="full_name" className="mt-0">Full Name <span>*</span></label>
                                                <input type="text" id="full_name" name="full_name" className="form-control" placeholder="Enter your name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="phone_number">Phone Number <span>*</span></label>
                                                <input type="tel" id="phone_number" name="phone_number" className="form-control" placeholder="Enter phone number" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="email">Email <span>*</span></label>
                                                <input type="email" id="email" name="email" className="form-control" placeholder="Enter email" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="message">Message <span>*</span></label>
                                                <textarea name="message" id="message" rows="5" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contactusbtn">
                                                <button type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}