import React from "react";
import "./testimonials.css";
// import { Modal, Button } from 'react-bootstrap';
// import YouTube from 'react-youtube';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Breadcrumb from "../../Components/Breadcrumb/breadcrumb";
import { FaStar } from "react-icons/fa";
import Testimonialimg from "../../Assets/testimonial.png"
// import { VideoTestimonialsdata } from "../../Components/VideoTestimonials/videotestidata";
// import { FaYoutube } from "react-icons/fa6";
// import LazyImage from '../../Components/LazyLoader/LazyImage';

export default function Testimonials(){
    // const [isOpen, setIsOpen] = useState(false);
    // const [videoId, setVideoId] = useState('');
    // const handleClose = () => setIsOpen(false);
    // const openModal = (id) => {
    //     setVideoId(id);
    //     setIsOpen(true);
    // };
    // const closeModal = () => {
    //     setIsOpen(false);
    //     setVideoId('');
    // };
    // const opts = {
    //     height: '500px',
    //     width: '100%',
    //     playerVars: {
    //         autoplay: 1, 
    //         rel: 0, 
    //     },
    // };
    return(
        <React.Fragment>
            <Header/>
            <Breadcrumb title={'Testimonials'}/>
            <section className="testimonials">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="testimonialsgrid">
                                <div className="testimonialsbox">
                                    <div className="testimonialsstar">
                                        {[...Array(5)].map((_, index) => (
                                            <FaStar key={index} />
                                        ))}
                                    </div>
                                    <div className="testimonialscontent">
                                        <p>Right from our initial consultation, Nectar was committed to understand my business goals and tailoring a comprehensive strategy to achieve them. Quick, efficient, transparent and customer focused approach is their strength.</p>
                                    </div>
                                    <div className="testimonialbottom">
                                        <div className="testimonialbottotitl">
                                            <h2>Name here</h2>
                                            <h3>Designation</h3>
                                        </div>
                                        <div class="testimonial-line"></div>
                                        <div className="testimoniimg">
                                            <img src={Testimonialimg} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="testimonialsgrid">
                                <div className="testimonialsbox">
                                    <div className="testimonialsstar">
                                        {[...Array(5)].map((_, index) => (
                                            <FaStar key={index} />
                                        ))}
                                    </div>
                                    <div className="testimonialscontent">
                                        <p>Right from our initial consultation, Nectar was committed to understand my business goals and tailoring a comprehensive strategy to achieve them. Quick, efficient, transparent and customer focused approach is their strength.</p>
                                    </div>
                                    <div className="testimonialbottom">
                                        <div className="testimonialbottotitl">
                                            <h2>Name here</h2>
                                            <h3>Designation</h3>
                                        </div>
                                        <div class="testimonial-line"></div>
                                        <div className="testimoniimg">
                                            <img src={Testimonialimg} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="testimonialsgrid">
                                <div className="testimonialsbox">
                                    <div className="testimonialsstar">
                                        {[...Array(5)].map((_, index) => (
                                            <FaStar key={index} />
                                        ))}
                                    </div>
                                    <div className="testimonialscontent">
                                        <p>Right from our initial consultation, Nectar was committed to understand my business goals and tailoring a comprehensive strategy to achieve them. Quick, efficient, transparent and customer focused approach is their strength.</p>
                                    </div>
                                    <div className="testimonialbottom">
                                        <div className="testimonialbottotitl">
                                            <h2>Name here</h2>
                                            <h3>Designation</h3>
                                        </div>
                                        <div class="testimonial-line"></div>
                                        <div className="testimoniimg">
                                            <img src={Testimonialimg} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="testimonialsgrid">
                                <div className="testimonialsbox">
                                    <div className="testimonialsstar">
                                        {[...Array(5)].map((_, index) => (
                                            <FaStar key={index} />
                                        ))}
                                    </div>
                                    <div className="testimonialscontent">
                                        <p>Right from our initial consultation, Nectar was committed to understand my business goals and tailoring a comprehensive strategy to achieve them. Quick, efficient, transparent and customer focused approach is their strength.</p>
                                    </div>
                                    <div className="testimonialbottom">
                                        <div className="testimonialbottotitl">
                                            <h2>Name here</h2>
                                            <h3>Designation</h3>
                                        </div>
                                        <div class="testimonial-line"></div>
                                        <div className="testimoniimg">
                                            <img src={Testimonialimg} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="testimonialsgrid">
                                <div className="testimonialsbox">
                                    <div className="testimonialsstar">
                                        {[...Array(5)].map((_, index) => (
                                            <FaStar key={index} />
                                        ))}
                                    </div>
                                    <div className="testimonialscontent">
                                        <p>Right from our initial consultation, Nectar was committed to understand my business goals and tailoring a comprehensive strategy to achieve them. Quick, efficient, transparent and customer focused approach is their strength.</p>
                                    </div>
                                    <div className="testimonialbottom">
                                        <div className="testimonialbottotitl">
                                            <h2>Name here</h2>
                                            <h3>Designation</h3>
                                        </div>
                                        <div class="testimonial-line"></div>
                                        <div className="testimoniimg">
                                            <img src={Testimonialimg} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="testimonialsgrid">
                                <div className="testimonialsbox">
                                    <div className="testimonialsstar">
                                        {[...Array(5)].map((_, index) => (
                                            <FaStar key={index} />
                                        ))}
                                    </div>
                                    <div className="testimonialscontent">
                                        <p>Right from our initial consultation, Nectar was committed to understand my business goals and tailoring a comprehensive strategy to achieve them. Quick, efficient, transparent and customer focused approach is their strength.</p>
                                    </div>
                                    <div className="testimonialbottom">
                                        <div className="testimonialbottotitl">
                                            <h2>Name here</h2>
                                            <h3>Designation</h3>
                                        </div>
                                        <div class="testimonial-line"></div>
                                        <div className="testimoniimg">
                                            <img src={Testimonialimg} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="videotestimonialsarea">
                        <div className="videotestiheader">
                            <h2>Video Testimonials</h2>
                        </div>
                        <div className="row">
                            {VideoTestimonialsdata.map((testimonials) => (
                                <div className="col-lg-4">
                                    <div className="videotestipagegrid" key={testimonials.id}>
                                        <LazyImage src={`https://img.youtube.com/vi/${testimonials.videoid}/hqdefault.jpg`} alt="YouTube Thumbnail" onClick={() => openModal(testimonials.videoid )} style={{ cursor: 'pointer' }} />
                                        <div className="videobtns" onClick={() => openModal(testimonials.videoid)}><FaYoutube/></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> */}
                </div>
            </section>
            {/* <Modal show={isOpen} onHide={closeModal} centered size="lg" dialogClassName="videopopup">
                <Modal.Body>
                    <div className="videopopupclose">
                        <Button variant="close" onClick={handleClose} aria-label="Close"></Button>
                    </div>
                    {videoId && <YouTube videoId={videoId} opts={opts} />}
                </Modal.Body>
            </Modal> */}
            <Footer/>
        </React.Fragment>
    )
}