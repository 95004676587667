import React, { useState } from "react";
import "./videotestimonials.css";
import { Modal, Button } from 'react-bootstrap';
import YouTube from 'react-youtube';
import Sliders from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { VideoTestimonialsdata } from "./videotestidata";
import { FaYoutube } from "react-icons/fa6";
import LazyImage from '../LazyLoader/LazyImage';

const videotestsetting = {
    dots: true,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: false,
    arrows: false,
    responsive: [
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
            },
        },
    ]
  };
export default function VideoTestimonials(){
    const [isOpen, setIsOpen] = useState(false);
    const [videoId, setVideoId] = useState('');
    const handleClose = () => setIsOpen(false);
    const openModal = (id) => {
        setVideoId(id);
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
        setVideoId('');
    };
    const opts = {
        height: '500px',
        width: '100%',
        playerVars: {
            autoplay: 1, 
            rel: 0, 
        },
    };
    return(
        <React.Fragment>
            <section className="videotestimonials">
                <div className="container">
                    <div className="videotestihead">
                        <h3>Video Testimonials</h3>
                        <h2>Watch Our Video</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div className="videotestidata">
                        <Sliders {...videotestsetting}>
                            {VideoTestimonialsdata.map((testimonials) => (
                                <div className="videotestgrid" key={testimonials.id}>
                                    <LazyImage src={`https://img.youtube.com/vi/${testimonials.videoid}/hqdefault.jpg`} alt="YouTube Thumbnail" onClick={() => openModal(testimonials.videoid )} style={{ cursor: 'pointer' }} />
                                    <div className="videobtns" onClick={() => openModal(testimonials.videoid)}><FaYoutube/></div>
                                </div>
                            ))}
                        </Sliders>
                    </div>
                    <Modal show={isOpen} onHide={closeModal} centered size="lg" dialogClassName="videopopup">
                        <Modal.Body>
                            <div className="videopopupclose">
                                <Button variant="close" onClick={handleClose} aria-label="Close"></Button>
                            </div>
                            {videoId && <YouTube videoId={videoId} opts={opts} />}
                        </Modal.Body>
                    </Modal>
                </div>
            </section>
        </React.Fragment>
    )
}