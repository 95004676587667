import React from "react";
import "./guestspeaker.css";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Breadcrumb from "../../Components/Breadcrumb/breadcrumb";

export default function GuestSpeaker(){
    return(
        <React.Fragment>
            <Header/>
            <Breadcrumb title={'Be our Guest Speaker'}/>
            <section className="beourguest">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="guestspeaker">
                                <form action="" method="post">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="first_name" className="mt-0">First Name <span>*</span></label>
                                                <input type="text" id="first_name" name="first_name" className="form-control" placeholder="Enter first name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="last_name" className="mt-0">Last Name <span>*</span></label>
                                                <input type="text" id="last_name" name="last_name" className="form-control" placeholder="Enter last name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="company_name">⁠⁠Company Name <span>*</span></label>
                                                <input type="text" id="company_name" name="company_name" className="form-control" placeholder="Enter ⁠Company name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="whatsapp_number">WhatsApp Number <span>*</span></label>
                                                <input type="text" id="whatsapp_number" name="whatsapp_number" className="form-control" placeholder="Enter ⁠WhatsApp Number" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="linkedin_link">⁠⁠LinkedIn Link <span>*</span></label>
                                                <input type="text" id="linkedin_link" name="linkedin_link" className="form-control" placeholder="Enter ⁠⁠LinkedIn Link" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="⁠⁠presentation_title">⁠⁠⁠Presentation Title <span>*</span></label>
                                                <input type="text" id="⁠⁠presentation_title" name="⁠⁠presentation_title" className="form-control" placeholder="Enter ⁠Presentation Title" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="speaker_introduction">⁠⁠Speaker’s Introduction <span>*</span></label>
                                                <input type="text" id="speaker_introduction" name="speaker_introduction" className="form-control" placeholder="Enter ⁠Speaker’s Introduction" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="interactive_activity">⁠One Interactive Activity <span>*</span></label>
                                                <input type="text" id="interactive_activity" name="interactive_activity" className="form-control" placeholder="Enter ⁠One Interactive Activity" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="audience_take_aways">⁠⁠3 Audience Take Aways <span>*</span></label>
                                                <input type="text" id="audience_take_aways" name="audience_take_aways" className="form-control" placeholder="Enter ⁠3 Audience Take Aways" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="other_notes">Any Other Notes</label>
                                                <textarea name="other_notes" id="other_notes" rows="5" className="form-control" placeholder="Any Other Notes"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="speakerformbtn">
                                                <button type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}