
const API_URL = "https://therightcoachonline.bluedigitaltechnologies.com/adminpanel";
// const API_URL = "http://localhost/Right_Coach_React_backend";
const APP_URL = "https://therightcoachonline.bluedigitaltechnologies.com";

const Apis = {
  Register: `${API_URL}/api/user-register`,
  Login: `${API_URL}/api/user-login`,
  LoginGoogle: `${API_URL}/api/user-login-google`
};
export { API_URL, Apis, APP_URL };