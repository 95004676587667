import React, { useState, useEffect } from 'react';
import "./footer.css";
import { Link } from "react-router-dom";
import Mainlogo from "../../Assets/mainlogo.png"
import { FaEnvelopeOpenText, FaInstagram, FaLinkedinIn, FaXmark } from "react-icons/fa6";
import LazyImage from '../LazyLoader/LazyImage';

export default function Footer(){
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    const handleClose = () => {
        setIsVisible(false);
    };
    return(
        <React.Fragment>
            <footer className="mainfooter">
                <div className="container">
                    <section className="newsletterarea">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="newlettercontent">
                                    <h2>Join Our Newsletter.</h2>
                                    <p>Sign up our newsletter to get update or new insight.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="newletterform">
                                    <form action="" method="post">
                                        <div className="row">
                                            <div className="col-lg-9 col-md-9 col-sm-8 col-xs-8 col-8">
                                                <div className="from-group">
                                                    <input type="email" name="email" className="form-control" placeholder="Enter your email" />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-4 col-xs-4 col-4">
                                                <div className="from-group">
                                                    <button type="submit">Sign Up</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="row justify-content-between">
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="footerwidget">
                                <Link to="/"><LazyImage src={Mainlogo} alt="" /></Link>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-xs-4 col-4">
                            <div className="footerwidget">
                                <h2>Quick Links</h2>
                                <ul>
                                    <li><Link to="/events/upcoming-events/">Upcoming Events</Link></li>
                                    <li><Link to="/about-hana/">About Hana</Link></li>
                                    <li><Link to="/testimonials/">Testimonials</Link></li>
                                    <li><Link to="/blogs/">Blogs</Link></li>
                                    <li><Link to="/contact-us/">Contact us</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-xs-4 col-4">
                            <div className="footerwidget">
                                <h2>Services</h2>
                                <ul>
                                    <li><Link to="/be-our-gest-speaker/">Be our Guest Speaker</Link></li>
                                    <li><Link to="/business-listing/">Business Listing</Link></li>
                                    <li><Link to="/connection-call/">Connection Call</Link></li>
                                    <li><Link to="/strategy-call/">Strategy Call</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-4 col-xs-4 col-4">
                            <div className="footerwidget">
                                <h2>Company</h2>
                                <ul>
                                    <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
                                    <li><Link to="/terms-conditions/">Terms & Conditions </Link></li>
                                    <li><Link to="/contact-us/">Contact us</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="footerwidget">
                                <h2>Contact us</h2>
                                <ul>
                                    <li><FaEnvelopeOpenText/> <Link to="mailto:hello@yourdomain.com">hello@yourdomain.com</Link></li>
                                </ul>
                                <div className="socialmedia">
                                    <ul>
                                        <li><Link to="http://www.instagram.com/theright.coach" target="_blank"><FaInstagram/></Link></li>
                                        <li><Link to="http://www.linkedin.com/in/hanamohamed" target="_blank"><FaLinkedinIn/></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <section className="copyrights">
                <div className="container">
                    <p>© The Right Coach Online. All Rights Reserved, 2024 | Developed by <Link to="https://bluedigitaltechnologies.com/" target="_blank">Blue Digital Technologies</Link>.</p>
                </div>
            </section>
            {isVisible && (
                <div className="alert offBar">
                    <div className="close_btn fordesktopsd">
                        <button type="button" className="close" onClick={handleClose}><FaXmark/></button>
                    </div>
                    <div className="permotionntofi">
                        <div className="promologo">
                            <div className="logo_holder">
                                <Link to="#"> <LazyImage src={Mainlogo} alt="" /></Link>
                            </div>
                        </div>
                        <div className="promotcontent">
                            <div className="tag_line">Showcase your business and reach an audience excited to see what you offer—boost your visibility and open doors to new opportunities!</div>
                        </div>
                        <div className="promotcuts">
                            <div className="text-center offer_btn"><Link to="#" className="cta_seo_strip">JOIN NOW</Link></div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}